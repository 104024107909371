import React from 'react'
import ProjectList from './projects/projectList';
import ProjectFooter from './projects/projectFooter';

function AppProjects() {
  return (
    <div>
      <ProjectList />
      <ProjectFooter />
    </div>
  );
};

export default AppProjects