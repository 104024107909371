import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppHome from './components/appHome'
import AppHeader from './components/appHeader'
import AppProjects from './components/appProjects'
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <AppHeader />
        <Routes>
          <Route path="/" element={<AppHome />}/>
          <Route path="/projects" element={<AppProjects />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
