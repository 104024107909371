import React from 'react'

function HomeFooter() {
  return (
    <div className='container mx-auto flex flex-col items-center justify-center text-center m-6'>
      <a className='footer-link' href='https://github.com/TZajas/tomaszzajas.ie'>Made using <span className='react'>ReactJS</span>, <span className='tailwind'>Tailwind</span> and &#10084; by Tomasz.</a>
    </div>
  );
};

export default HomeFooter