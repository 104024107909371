import React from 'react';
import '../../css/projectFooter.css'

function ProjectFooter() {
    return (
        <div className='footer-container border-t border-gray-600 p-5' >
            <a className='footer-link' href='https://github.com/TZajas/tomaszzajas.ie'>
                Made using <span className='react'>ReactJS</span>, <span className='tailwind'>Tailwind</span> and &#10084; by Tomasz.
            </a>
        </div>
    );
}

export default ProjectFooter;
