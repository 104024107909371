import React from 'react'
import '../../css/about.css'
import photo from '../../assets/Tomasz-Headshot.png'

function About() {
  return (
    <div className='container mx-auto flex flex-col items-center justify-center text-center mt-20 border-b border-gray-600'>
      <div className='mb-20'>
          <div className='about-big-text font-bold huemint'>About Me</div>
          <div className='mt-8 container mx-auto flex items-center justify-center'>
              <img src={photo} alt='headshot' />
              <p className='text-gray-300'>Hello! I'm Tomasz Zajas, currently in my final year as a dedicated computer systems
                  student at the <span className='text-green-400'>University of Limerick</span>. With a strong foundation in technical 
                  knowledge and a passion for problem-solving, I am eagerly preparing to transition 
                  my skills from the educational environment to the dynamic and challenging world of professional 
                  work. My goal is to contribute my expertise to meaningful projects and to continue learning and growing in the ever-evolving field of technology.</p>
          </div>
      </div>
    </div>
  );
};

export default About