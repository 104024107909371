import React from 'react'
import "../css/appHeader.css"
import { NavLink, useLocation } from 'react-router-dom'

function AppHeader() {
    const location = useLocation().pathname
    
    return (
      <header className="p-3 header-bg text-white text-xl border-b border-gray-600 sticky top-0 z-50">
        <div className="container mx-auto flex items-center justify-around">
          <div className="flex items-center space-x-4">
            <a href='/' className='text-3xl mr-5 hover:text-gray-400'>TZ</a>
            <NavLink to='/' className={location === '/' ? 'underline underline-offset text-2xl huemint hover:text-orange-300' : 'hover:text-gray-400'}>
              Home
            </NavLink>
            <NavLink to='/projects' className={location === '/projects' ? 'underline underline-offset text-2xl huemint hover:text-orange-300' : 'hover:text-gray-400'}>
              Projects
            </NavLink>
          </div>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/tomasz-zajas">
            <button
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="flex rounded px-5 py-2 text-sm font-semibold uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg huemint-bg hover:bg-orange-300 linkedin-btn"
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24">
                <path
                  d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
              Connect
            </button>
          </a>
        </div>
      </header>
    );
};
    

export default AppHeader