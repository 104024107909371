import React from 'react'
import '../../css/careerCard.css'

function CareerCard({path}) {

  return (
    <div className="max-w-xl card-bg text-white shadow-md rounded-md overflow-hidden">
      <div className="flex">
        <div className="w-1/10 pt-4 pr-4 pl-4">
          <img
            src={path.logo}
            alt="Company Logo"
            className="w-14 h-14 object-contain"
          />
        </div>
        <div className="w-4/5 pt-4 pb-4">
          <div className="flex justify-between items-start">
            <h2 className="text-xl font-bold">{path.title}</h2>
            <div class="flex items-center">
            <span>
              <svg className='text-gray-400 ' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3" fill="currentColor"/>
              </svg>
            </span>
              <a href={path.link}><p class="text-right text-base font-semibold ml-2"><u class={path.class[0]}>{path.location}</u></p></a>
            </div>
          </div>
          <p className="mb-2 text-gray-400 text-sm">{path.duration}</p>
          <p className="desc mb-4 text-gray-200">{path.description}</p>
          <div className="mt-4">
            {path.skills.map((skill, index) => (
              <span
                key={index}
                className={`inline-block text-white font-semibold text-sm px-3 py-1 mt-1 mr-2 ${path.class[1]}`}
                style={{ borderRadius: '1rem' }}
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareerCard