import React, { useState } from 'react';
import '../../css/projectCard.css'
import ProjectModal from './projectModal';

function ProjectCard({ project }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Exclude the iframe from the animation
  return (
    <div className='p-10'>
      <div className="max-w-lg rounded-lg overflow-hidden project-card-bg border border-gray-800 hover:bg-gray-900"
      onClick={() => openModal()}
      style={{ cursor: 'pointer' }}>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{project.title}</div>
          <p className="text-gray-400 text-base">
            {project.desc}
          </p>
        </div>
        <div className="px-6 pb-2">
          { project.skills.map((skill, index) => (
            <span key={index} className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 ${skill[1]} `}>{skill[0]}</span>
          ))}
        </div>
      </div>
      <ProjectModal isOpen={isModalOpen} onClose={closeModal} project={project}/>
    </div>
  );
}

export default ProjectCard;





