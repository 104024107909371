import React from 'react'
import ProjectCard from './projectCard'
import '../../css/projectList.css'


const projects = [
    {
      title: 'WiredIn',
      desc: 'LinkedIn like application specially catered for software engineers. Made as part of a group project using PHP, MySQL, and Bootstrap.',
      long_desc: "As part of a collaborative group project, I contributed to the development of a specialized social networking platform designed exclusively for software engineers, akin to LinkedIn but tailored to the unique needs and interests of the tech community. Leveraging PHP, MySQL, and Bootstrap, we crafted an interactive and feature-rich platform that serves as a hub for software engineers to connect, share knowledge, showcase their projects, and forge professional relationships. \n\nOur application offers a user-friendly and responsive design thanks to Bootstrap, making it accessible on a range of devices. It also incorporates a robust back-end powered by PHP and MySQL for data management, user authentication, and content delivery. \n\nThis project not only reflects my strong skills in web development but also demonstrates my passion for creating tools that cater to specific professional niches, ultimately contributing to the growth and collaboration of the software engineering community.",
      video: 'https://www.youtube.com/embed/TNUD2F_htO0?si=jDlbIYtxWyF7Ds83',
      skills: [['PHP', 'php-bg'], ['MySQL', 'mySql-bg'], ['Bootstrap', 'boot-bg']],
      github: 'https://github.com/Dawid33/CS4116'
    },
    {
      title: 'Safe Campus',
      desc: 'Android application that allows user to alert previously added contacts with a pre-set message and current location with the click of a button. Made using Java and Android Studio.',
      long_desc: "I developed an Android application using Java and Android Studio that empowers users to swiftly alert their pre-set emergency contacts in critical situations. The app provides a simple and intuitive interface with a prominent button that, when pressed, sends a pre-set message along with the user's current location to a list of previously added contacts. The project involved various key components, such as integrating location services for real-time tracking, creating a user-friendly interface, and managing contacts and messages effectively.",
      video: 'https://www.youtube.com/embed/G5pLbGvhgb0?si=ePRFdGJqtKKDeuMT',
      skills: [['Java', 'java-bg'], ['Android Studio', 'ad-bg']],
      github: 'https://github.com/TZajas/CS4084-App-Dev'
    },
    {
      title: 'Dogs CEO API',
      desc: 'Angular project that demonstrates understanding of APIs, HTTPS requests and, the Angular Framework.',
      long_desc: 'In this Angular project, I showcased a comprehensive grasp of working with APIs, handling HTTPS requests, and leveraging the robust capabilities of the Angular framework. Through this project, I demonstrated my proficiency in integrating various external APIs seamlessly within the Angular environment, enabling dynamic data retrieval and manipulation. By implementing secure HTTPS requests, I ensured the confidentiality and integrity of data transmission, underscoring my commitment to upholding stringent security protocols.',
      video: 'https://www.youtube.com/embed/mF3cXOaGKrI?si=cXtrNxN600e8GhfY',
      skills: [['Angular', 'ag-bg'], ['API', 'api-bg'], ['HTTPS', 'https-bg']],
      github: 'https://github.com/TZajas/Dogs-Ceo-API-Project'
    },
    {
      title: 'QCA Calculator',
      desc: "Angular application which calculates grades based on the University of Limerick's (QCA) Quality Credit Average system.",
      long_desc: "In this Angular application I developed, I tackled the complex task of calculating grades according to the University of Limerick's Quality Credit Average (QCA) system. This project underscores my in-depth understanding of both the academic grading systems and the Angular framework. It involved the implementation of a user-friendly interface where students can input their grades and credits for each course, and the application then automatically calculates the QCA, a crucial metric for academic performance. This project showcases my proficiency in Angular, including data binding, form handling, and complex calculations, as well as my ability to address real-world challenges in the academic field.",
      video: 'https://www.youtube.com/embed/FWnLsB3XRBo?si=-yzkcnClwzOaf5ZM',
      skills: [['Angular', 'ag-bg']],
      github: 'https://github.com/TZajas/QCA-Calculator'
    }
  ];


function ProjectList() {
  return (
    <div className="flex justify-center items-center mt-20">
      <div className="grid grid-cols-2 mb-20">
        {projects.map((project, index) => (
          <div key={index} className="flex justify-center items-center custom-gap">
            <ProjectCard project={project} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProjectList