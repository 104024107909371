import React from 'react'
import "../css/appHome.css"
import About from './home/about';
import Career from './home/career';
import Title from './home/title';
import HomeFooter from './home/homeFooter';

function AppHome() {
  return (
    <div>
      <Title />
      <About />
      <Career />
      <HomeFooter />
    </div>
  );
};



export default AppHome