import React from 'react'
import CareerCard from './careerCard'
import ULLogo from '../../assets/ul.png'

const ul = {
    title: "Computer Systems Student",
    duration: "Sept 2020 - Present",
    location: "UL",
    description: "Throughout my academic journey, I have consistently prioritized a commitment to excellence, achieving a notable Quality Credit Average (QCA) of 3.84. My commitment to both academics and the field of technology was further recognized when I was honored with the Huawei Seeds for the Future Scholarship Award during my third year. This achievement reflects my dedication and passion for a future career in the tech industry.",
    logo: ULLogo,
    class: ["ul", "ul-tag"],
    link: "https://www.ul.ie/",
    skills: ["System Architecture", "Java", "Go", "Project Management"]
  }

const bd = {
    title: "Software Engineer Intern",
    duration: "May 2022 - Jan 2023",
    location: "Becton Dickinson",
    description: "During my cooperative placement, I actively contributed to both the front-end and back-end development of a sophisticated browser-based application, utilizing the Angular framework and C#. This application streamlined access to laboratory workflows and data, ensuring a seamless user experience. Operating as a crucial member of a substantial sprint team, I actively participated in regular stand-up meetings, sprint planning sessions, and monthly backlog refinement meetings, fostering seamless collaboration and effective project coordination. My experience encompassed invaluable expertise in C#, Angular, Git version control, and Azure DevOps, thus enriching my skill set in software development, version control, and project management practices.",
    logo: "https://s3-symbol-logo.tradingview.com/becton-dickinson--600.png",
    class: ["bd", "bd-tag"],
    link: "https://jobs.bd.com/limerick-ireland",
    skills: ["C#", "Angular", "TypeScript", "ADO"]
  }

const outfield = {
    title: "Software Engineer Intern",
    duration: "June 2023 - August 2023",
    location: "Outfield Technologies",
    description: "During my summer internship, I spearheaded the development of a sophisticated web application tailored for the collection and analysis of drone image data within orchards, offering users insightful reports through an engaging interactive map interface. Operating adeptly within a Scrum framework characterized by weekly sprint cycles, daily stand-up meetings, and comprehensive sprint planning and retrospective evaluations, I gained valuable expertise in Django, React, Git, and REST API integration, further enhancing my proficiency in web development and project management.",
    logo: "https://media.licdn.com/dms/image/C4D0BAQHKLXxKVCNStQ/company-logo_200_200/0/1631303859029?e=2147483647&v=beta&t=k_X1McQe8h0fDQw7KtiPZ60MlRQQo7ZT4bF-GC3cheg",
    class: ["outfield", "outfield-tag"],
    link: "https://outfield.xyz/",
    skills: ["Django", "ReactJS", "Rest APIs", "Web Architecture", "Git"]
  }

function Career() {
  return (
    <div className='container mx-auto flex flex-col text-center mt-20 border-b border-gray-600'>
      <div className='mb-20'>
        <div className='about-big-text font-bold huemint text-center'>Career Path</div>
        <div className='flex flex-col items-center mt-6'>
          <div className='mt-8 mr-80'>
            <CareerCard  path={ul}/>
          </div>
          <div className='mt-8 ml-80'>
            <CareerCard path={outfield}/>
          </div>
          <div className='mt-8 mr-80'>
            <CareerCard path={bd}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career